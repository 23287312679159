<template>
    <v-app>
        <v-container>
          <h3>Create Order</h3>
          <v-btn
              text
              :to="{ name: 'orders-list', params: { status: 'all' }}"
              outlined
              class="btn btn-lg btn-secondary-main"
              style="background:transparent;"
          >
            <i class="fas fa-arrow-left"></i> Back
          </v-btn>
          <div class="row mt-2" v-if="loading">
            <div class="col-md-3">
              <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
            </div>
            <div class="col-md-3">
              <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
            </div>
            <div class="col-md-3">
              <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
            </div>
            <div class="col-md-3">
              <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
            </div>
          </div>
          <div class="mt-2" v-else>

            <v-card flat class="mb-5">
              <v-card-text>
                <v-row>
                  <v-col cols="6" sm="12" md="3" lg="10">
                    <v-text-field outlined dense v-model="productFilter.title"
                                  label="Search Product"></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="12" md="9" lg="2" @click="getProducts">
                    <v-btn color="primary">Search</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-row>
              <v-col cols="6" sm="12" md="8" lg="8" class>
                <v-row>
                  <v-col cols="6" sm="12" md="4" lg="4" v-for="(p,i) of products" :key="i">
                    <v-card height="100%">
                      <v-card-text>
                        <v-checkbox
                            v-if="p.price"
                            outlined
                            densed
                            class="pull-right"
                            @change="onProductSelect(p,i)"
                            :readonly="selected.includes(i)"
                            v-model="selected"
                            :value="i"
                        ></v-checkbox>
                        <progressive-img
                            :src="p.medias.length>0 ? image(p.medias[0].image_path) : '' "
                        />
                        <br/>
                        <h4 class="mt-2 font-weight-bold">{{p.title}}</h4>
                        <p class="font-weight-bold" v-if="p.enable_special_price">
                          {{`${p.symbol} ${p.special_price}`}}
                          <strike>{{`${p.symbol} ${p.price}`}}</strike>
                        </p>
                        <p class="font-weight-bold" v-else>{{`${p.symbol} ${p.price}`}}</p>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="6" sm="12" md="4" lg="4" v-if="order_list.length>0">
                <v-card flat>
                  <v-card-text>
                    <h3>Order List</h3>
                    <hr class="border"/>
                    <v-row v-for="(p,i) of order_list" :key="i">
                      <v-col cols="3">
                        <progressive-img
                            :src="p.medias.length>0 ? image(p.medias[0].image_path) :'https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/No_image_available_600_x_450.svg/600px-No_image_available_600_x_450.svg.png' "
                            style="max-width:100%; height:auto"
                        />
                      </v-col>
                      <v-col cols="7">
                        <h4 class="mt-2 font-weight-bold">
                          {{p.title}} X {{p.qty}}

                        </h4>
                        <p class="font-weight-bold" v-if="p.enable_special_price">
                          {{`${p.symbol} ${p.special_price}`}}
                          <strike>{{`${p.symbol} ${p.price}`}}</strike>
                        </p>
                        <p class="font-weight-bold" v-else>{{`${p.symbol} ${p.price}`}}
                          <span
                              class="pull-right"
                              style="cursor:pointer"
                              @click="removeItem(i)"
                          >
                      <i class="fas fa-trash text-danger"></i> Remove
                    </span>
                        </p>
                      </v-col>
                    </v-row>
                    <h3 class="mt-2s">Summary</h3>
                    <hr class="border"/>
                    <v-row>
                      <v-col cols="12" class="mr-2">
                        <h5>
                          Sub Total
                          <span class="pull-right">{{`${symbol}${sub_total}` }}</span>
                        </h5>
                        <h5>
                          Tax
                          <span class="pull-right">{{`${symbol}${tax}` }}</span>
                        </h5>
                        <h4>
                          Total
                          <span class="pull-right">{{`${symbol}${total}` }}</span>
                        </h4>
                      </v-col>
                      <hr class="border"/>
                      <v-col cols="12" class="mr-2">
                        <v-btn
                            color="white--text v-btn   theme--light elevation-2 v-size--large primary"
                            text
                            :loading="isBusy"
                            class="pull-right btn btn-primary btn-elevate kt-login__btn-primary kt-spinner--light kt-spinner--right"
                            @click="openOrderDialog"
                        >Checkout
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="text-center" v-else>
                <v-card>
                  <v-card-title>
                    Summary
                  </v-card-title>
                  <v-card-text>
                    <img src="@/assets/media/icons/no-data-found.png" class="no-data-img">
                    <h5 class="mt-10">Select your products and add it to the list.</h5>
                  </v-card-text>
                </v-card>


              </v-col>
            </v-row>
            <order-form
                :dialog="dialog"
                :country_id="country_id"
                :order_list="order_list"
                :isBusy="isBusy"
                @close_dialog="closeDialog"
                @create_order="createOrder"
            ></order-form>
            <v-row v-if="last_page>0">
              <v-col sm="12" md="12" lg="12">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    @input="getProducts"
                ></b-pagination>
              </v-col>
            </v-row>
          </div>
        </v-container>
        <v-dialog scrollable v-model="qtyDialog" width="300">
            <v-card>
                <v-card-title class="grey lighten-2">Select Quantity</v-card-title>

                <v-card-text>
                    <v-row class="text-center mt-5">
                        <v-col cols="4">
                            <v-btn @click="decreaseQty">
                                <i class="fas fa-minus"></i>
                            </v-btn>
                        </v-col>
                        <v-col cols="4">
                            <v-text-field outlined dense v-model="order_qty"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-btn @click="increaseQty">
                                <i class="fas fa-plus"></i>
                            </v-btn>
                        </v-col>

                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="black" text @click="cancelOrder()">Cancel</v-btn>
                    <v-btn class="btn btn-primary" text @click="proceedOrder">Add</v-btn>
                </v-card-actions>
            </v-card>
            <!-- <pre>{{product}}</pre> -->
        </v-dialog>
    </v-app>
</template>
<script>
    import OrderForm from "./Order";
    import ProductService from "@/services/store/ProductService";
    import ManualOrderService from "@/services/store/ManualOrderService";

    const productService = new ProductService();
    const orderService = new ManualOrderService();

    export default {
        name: "manual-order",
        components: {
            OrderForm
        },
        data() {
            return {
                isBusy: false,
                key: [],
                products: [],
                product: {},
                order_qty: 1,
                index: null,
                dialog: false,
                qtyDialog: false,
                order_list: [],
                sub_total: 0,
                total: 0,
                tax: 0,
                selected: [],
                country_id: null,
                totalRows: 1,
                selectedIndex: null,
                currentPage: 1,
                perPage: 0,
                last_page: 0,
                productFilter: {},
                loading:true
            };
        },
        watch: {},
        mounted() {
            this.getProducts();
        },
        computed: {
            symbol() {
                this.country_id = this.products[0].country_id;
                return this.products[0].symbol;
            }
        },
        methods: {
            image(imagePath) {
                if (imagePath != null) return imagePath.thumb;
            },
            openOrderDialog() {
                this.dialog = true;
            },
            closeDialog() {
                this.dialog = false;
            },
          cancelOrder() {
            this.selected.splice(this.selected.indexOf(this.selectedIndex), 1);
            this.selectedIndex = null;
                this.qtyDialog = false;
            },
            getProducts() {
                productService
                    .paginate(this.productFilter, this.currentPage)
                    .then(res => {
                        this.products = res.data.data;
                        this.currentPage = res.data.meta.current_page;
                        this.perPage = res.data.meta.per_page;
                        this.last_page = res.data.meta.last_page;
                        this.totalRows = res.data.meta.total;
                    })
                    .catch(error => {
                        // console.log(error);
                    })
                    .finally(() => {
                      this.loading=false
                    });
            },
            calculateOrderTotal() {
                let amount = 0;
                if (this.order_list.length > 0) {
                    this.order_list.forEach(ele => {
                        let price = 0;
                        if (ele.special_price && ele.enable_special_price) {
                            price = parseInt(ele.qty) * parseFloat(ele.special_price);
                        } else {
                            price = parseInt(ele.qty) * parseFloat(ele.price);
                        }
                        amount = parseFloat(amount) + parseFloat(price);
                    });
                }
                this.sub_total = amount;
                this.tax = parseFloat(this.sub_total) * 0.13;
                this.total = parseFloat(this.sub_total) + parseFloat(this.tax);
            },
            onProductSelect(product, index) {
                // this.qtyDialog = true;
              // console.log(index)
                this.selectedIndex = (index);
                this.product = product;
                this.order_qty = 1;
                if (this.product.type != "digital") {
                    this.qtyDialog = true;
                } else {
                    this.product.qty = this.order_qty;
                    this.order_list.push(this.product);
                    this.calculateOrderTotal();
                }
            },
            increaseQty() {
                if (
                    this.product.manage_stock) {
                    if (this.product.inventory_stock &&
                        this.product.inventory_stock > this.order_qty
                    ) {
                        this.order_qty = parseInt(this.order_qty) + 1;
                    }
                } else {
                    this.order_qty = parseInt(this.order_qty) + 1;
                }

            },
            proceedOrder() {
                this.qtyDialog = false;
                this.product.qty = this.order_qty;
                this.order_list.push(this.product);
                this.calculateOrderTotal();
                this.product = {};
            },
            decreaseQty() {
                if (this.order_qty > 1) {
                    this.order_qty = parseInt(this.order_qty) - 1;
                }
            },
            removeItem(index) {
                this.order_list.splice(index, 1);
                this.selected.splice(index, 1);
            },
            createOrder(data) {
                this.isBusy = true;
                orderService
                    .createOrder(data)
                    .then(response => {
                        this.closeDialog();
                        setTimeout(() => {
                            this.isBusy = false;
                            this.$router.push({
                                name: "orders-list",
                                params: {status: "all"}
                            });
                        }, 2000);
                    })
                    .catch(error => {
                        // console.log(error);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            }
        }
    };
</script>
