import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class EcommerceCustomerAddressService {

    #api;

    constructor() {
        this.#api = `user`;
    }

    paginate(userId, filter, index = null) {
        let url = `${this.#api}/${userId}/website-user/address`
        let query = new URLSearchParams(filter).toString();
        if (query)
            url = url + '?' + query
        return apiService.get(url);
    }


    byWebsite(id) {
        let url = `${this.#api}/${id}/all/website-user/filter`;
        return apiService.get(url);
    }

    create(userId, data) {
        let url = `${this.#api}/${userId}/website-user/address`
        return apiService.post(url, data);
    }

    update(userId, id, data) {
        let url = `${this.#api}/${userId}/website-user/address/${id}/update`;
        return apiService.put(url, data);
    }

    delete(userId, id) {
        let url = `${this.#api}/${userId}/website-user/address/${id}/delete`;
        return apiService.delete(url);
    }
    show(userId, id) {
        let url = `${this.#api}/${userId}/website-user/${id}`;
        return apiService.get(url);
    }

    validateEmail(userId,websiteId,  emaill){
        let url = `${this.#api}/${userId}/website/${websiteId}/validate-email/${emaill}`;
        return apiService.get(url);
    }
}
