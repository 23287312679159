<template>
  <v-row justify="center">
    <v-dialog scrollable v-model="dialog" persistent max-width="1000">
      <v-card>
        <v-card-title class="headline">Select Customer & Shipment</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                  outlined
                  densed
                  v-model="shipping.website_id"
                  :items="websites"
                  item-text="title"
                  item-value="id"
                  @change="getUser"
                  label="Select Website"
              ></v-select>
            </v-col>
            <!--                :loading="isLoading"-->
            <v-col cols="6">
              <v-autocomplete
                  v-model="model"
                  :items="users"
                  :search-input.sync="search"
                  outlined
                  dense
                  label="Start typing to Search customer"
                  placeholder="Start typing to Search customer"
                  return-object
                  item-text="full_name"
                  item-value="id"
                  @change="onUserSelect"
              >
              </v-autocomplete>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field outlined densed v-model="shipping.first_name" label="First Name"></v-text-field>
              <span class="text-danger" v-if="$v.shipping.first_name.$error">First Name is required</span>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined densed v-model="shipping.last_name" label="Last Name"></v-text-field>
              <span class="text-danger" v-if="$v.shipping.last_name.$error">Last Name is required</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field outlined densed v-model="shipping.primary_phone" label="Phone"></v-text-field>
              <span class="text-danger" v-if="$v.shipping.primary_phone.$error">Phone is required</span>
            </v-col>
            <v-col cols="6">
              <v-text-field type="email" outlined densed v-model="shipping.email" label="Email"></v-text-field>
              <span class="text-danger" v-if="$v.shipping.email.$error">Email is required</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-checkbox
                  outlined
                  dense
                  v-model="shipping.include_shipping"
                  label="Include Shipping"
              >Include Shipping
              </v-checkbox>
            </v-col>
          </v-row>

          <v-row v-if="shipping.buyer_user_id">
            <v-col cols="12">
              <h4>Shipping Address</h4>
              <v-btn class="pull-right" @click="showAddressForm">
                <i class="fas fa-plus"></i> Add Address
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-radio-group v-model="shipping.buyer_address_id">
                <v-radio
                    v-for="(a,index) in addresses"
                    :key="index"
                    :label="`${a.full_address}`"
                    :value="a.id"
                ></v-radio>
              </v-radio-group>
              <span
                  class="text-danger"
                  v-if="$v.shipping.buyer_address_id.$error"
              >Address is required</span>
            </v-col>
          </v-row>
          <v-row v-if="address_form">
            <v-col cols="6">
              <v-text-field outlined densed label="Address Line 1" v-model="address.address_line_1"></v-text-field>
              <span
                  class="text-danger"
                  v-if="$v.address.address_line_1.$error"
              >Address Line 1 is required</span>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined densed label="Address Line 2" v-model="address.address_line_2"></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field outlined densed label="Area" v-model="address.area"></v-text-field>
              <span class="text-danger" v-if="$v.address.area.$error">Area is required</span>
            </v-col>
            <v-col cols="6">
              <v-text-field outlined densed label="City" v-model="address.city"></v-text-field>
              <span class="text-danger" v-if="$v.address.city.$error">City is required</span>
            </v-col>

            <v-col cols="6">
              <v-text-field outlined densed label="State" v-model="address.state"></v-text-field>
              <span class="text-danger" v-if="$v.address.state.$error">State is required</span>
            </v-col>
            <v-col cols="6">
              <v-select
                  :items="['home', 'office', 'shipping', 'billing']"
                  label="Address Type"
                  v-model="address.type"
                  outlined
                  dense
              ></v-select>
            </v-col>
          </v-row>
          <!-- <pre>{{address}}</pre> -->
        </v-card-text>
        <v-card-actions v-if="!address_form">
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="closeDialog">Cancel</v-btn>
          <v-btn
              class="btn btn-primary"
              text
              :loading="isBusy"
              @click="createOrder"
          >Save & Confirm Order
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeAddressForm">Cancel</v-btn>
          <v-btn color="green darken-1" text :loading="isBusy" @click="saveAddress">Save Address</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import WebsiteService from "@/services/Websites/WebsiteService";
import WebsiteUserService from "@/services/Websites/WebsiteUserService";
import WebsiteUserAddressService from "@/services/store/EcommerceCustomerAddressService";

const addressService = new WebsiteUserAddressService();
const websiteService = new WebsiteService();
const websiteUserService = new WebsiteUserService();

export default {
  name: "order-form",
  props: ["dialog", "country_id", "order_list", "isBusy"],
  validations: {
    address: {
      address_line_1: {required},
      area: {required},
      city: {required},
      state: {required}
    },
    shipping: {
      first_name: {required},
      last_name: {required},
      primary_phone: {required},
      email: {required},
      buyer_address_id: {required}
    }
  },
  data: () => ({
    websites: [],
    address: {
      address_line_1: null,
      area: null,
      city: null,
      state: null,
      type: "home"
    },
    addresses: [],
    address_form: false,
    entries: [],
    isLoading: false,
    model: null,
    search: null,
    shipping: {
      first_name: null,
      last_name: null,
      primary_phone: null,
      email: null,
      buyer_address_id: null
    },
    users: [],
    items: []
  }),

  computed: {},

  watch: {
    search(newVal, oldVal) {
      if (newVal != oldVal) {
        websiteUserService
            .getByWebsiteUserAndKey(this.shipping.website_id, val)
            .then(response => {
              this.users = response.data;
              this.isLoading = false;
            })
            .catch(error => {
              // console.log(error);
            });
      }
    }
  },
  mounted() {
    this.getAllWebsite();
  },
  methods: {
    getAllWebsite() {
      websiteService
          .getAll()
          .then(response => {
            this.websites = response.data.data;
          })
          .catch(error => {
            // console.log(error);
          });
    },
    getUser() {
      websiteUserService
          .getByWebsiteUserAndKey(this.shipping.website_id)
          .then(response => {
            this.users = response.data;
            this.getItems();
          })
          .catch(error => {
            // console.log(error);
          });
    },
    getItems() {
      this.items = this.users.map(entry => {
        const User = entry.first_name + " " + entry.last_name;
        return Object.assign({}, entry, {User});
      });
    },
    closeDialog() {
      this.$emit("close_dialog");
    },
    onUserSelect() {
      this.shipping.buyer_user_id = this.model.id;
      this.shipping.first_name = this.model.first_name;
      this.shipping.last_name = this.model.last_name;
      this.shipping.primary_phone = this.model.phone;
      (this.shipping.email = this.model.email),
          (this.shipping.delivery_status = "pending");
      this.getUserAddress();
    },
    buildCart() {
      let cart = {
        type: "normal",
        website_id: this.shipping.website_id,
        buyer_user_id: this.model.id,
        country_id: this.country_id,
        payment_method: "cash",
        order_status: "complete",
        payment_status: "pending",
        payer_email: this.model.email,
        payer_name: this.model.first_name + " " + this.model.last_name,
        items: [],
        shipping: {}
      };
      let items = [];
      this.order_list.forEach(ele => {
        items.push({
          product_id: ele.id,
          item_price: ele.selling_price,
          qty: 1
        });
      });
      cart["items"] = items;
      cart["shipping"] = this.shipping;
      this.$emit("create_order", cart);
    },
    createOrder(cart) {
      this.$v.shipping.$touch();
      if (this.$v.shipping.$error) {
        setTimeout(() => {
          this.$v.shipping.$reset();
        }, 3000);
      } else {
        this.buildCart()
      }
    },
    showAddressForm() {
      this.address["country_id"] = this.country_id;
      this.address_form = true;
    },
    closeAddressForm() {
      this.address_form = false;
    },
    saveAddress() {
      this.$v.address.$touch();
      if (this.$v.address.$error) {
        setTimeout(() => {
          this.$v.address.$reset();
        }, 3000);
      } else {
        addressService
            .create(this.shipping.buyer_user_id, this.address)
            .then(response => {
              this.getUserAddress();
            })
            .catch(error => {
              // console.log(error);
            });
      }
    },
    getUserAddress() {
      addressService
          .paginate(this.shipping.buyer_user_id, null)
          .then(response => {
            this.addresses = response.data.data;
            // console.log(response.data);
            this.address_form = false;
          })
          .catch(error => {
          });
    }
  }
};
</script>
