import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
const apiService = Apiservice;


export default class ManualOrderService {

      createOrder(data){
            let url = API_URL + `user/create/order`;
            return apiService.post(url, data)
      }
}
