import Apiservice from '../../common/api.service';
import { API_URL } from '../../common/config.js'
import { email } from 'vuelidate/lib/validators';
const apiService = Apiservice;


export default class WebsiteUsersService {

      getByWebsiteUserAndKey(websiteId, key) {
            let url = API_URL + `user/website/${websiteId}/user`;
            if (key)
                  url = url + `?key=${key}`;
            return apiService.get(url);
      }


}
